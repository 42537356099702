import React, { useEffect } from "react";
import Address from "../Address";
import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";

const RoyalCar = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });

  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        data-image-src="./assets/img/casestudy/royal-banner0.png"
      >
        <div className="container pt-17 pb-12 pt-md-19 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link href="#" className="text-reset" rel="category">
                    Used car selling
                  </Link>
                </div>
                <h1 className="display-1 mb-3 text-white">
                  Revolutionizing the Car Mela B2B Marketplace
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/challenges.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The challenge faced by Royal Car system is...
              </h2>
            </div>
          </div>
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card me-lg-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">01</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Cluttered Documentation</h4>
                      <p className="mb-0">
                        The avalanche of purchase and repair histories made
                        management a headache.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-lg-13 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">02</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Financial Blur</h4>
                      <p className="mb-0">
                        It was increasingly challenging to clearly discern
                        profits and losses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-lg-6 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">03</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Disjointed Sales Records</h4>
                      <p className="mb-0">
                        Keeping a clear record of each sale was becoming an
                        uphill battle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Challenge
              </h2>
              <p className="lead fs-lg pe-lg-5">
                Streamlining vehicle bookings while enhancing user experience
                and car availability.
              </p>
              <p>
                In a bustling corner of the city, the used car marketplace
                thrived. However, managing a vast inventory of over a thousand
                cars wasn't an easy feat. Documents piled up, and the owner
                struggled to keep track of each car's history, from purchase and
                repairs to eventual sales. The overwhelming paperwork and the
                chaotic process of tracking profits and losses needed a major
                overhaul.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-14 pb-14 pb-md-18">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/solutions.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The solution we offer is ...
              </h2>
            </div>
          </div>

          <div className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center">
            <div className="col-lg-5 offset-lg-1">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/3d2.png"
                  srcSet="./assets/img/illustrations/3d2@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Solution
              </h2>
              <h3 className="display-5 mb-4">
                We bring solutions to make life easier to manage the assets.
              </h3>
              <p className="mb-6">
                Understanding the dire need for streamlined management,
                BytezTech stepped in with a comprehensive solution. We developed
                a robust system that consolidated every vital piece of
                information in one accessible platform. Now, tracking a car's
                history, from its acquisition, repairs, and additional costs, to
                its eventual sale became a hassle-free process. The owner could
                now easily view detailed reports, graphs, and insightful data,
                giving a clear picture of the business's financial health.
              </p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Unified Car Records</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Transparent Financial Insights</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Streamlined Sales Management</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img
                  className="img-fluid"
                  src="./assets/img/photos/about27.jpg"
                  srcSet="./assets/img/photos/about27@2x.jpg 2x"
                  alt=""
                />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                <div className="card-body py-4 px-5">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <img
                        src="./assets/img/icons/solid/cloud-group.svg"
                        className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="fs-25 counter mb-0 text-nowrap">43+</h3>
                      <p className="fs-16 lh-sm mb-0 text-nowrap">
                        Happy Owners
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card shadow-lg position-absolute text-center d-none d-md-block"
                style={{ bottom: "10%", left: "-10%" }}
              >
                <div className="card-body p-6">
                  <div
                    className="progressbar semi-circle fuchsia mb-3"
                    data-value="90"
                  ></div>
                  <h4 className="mb-0">Time Saved</h4>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                <img
                  src="./assets/img/icons/custom/results.svg"
                  className="svg-inject icon-svg icon-svg-md mb-4"
                  alt=""
                />
                <h2 className="display-4 mb-4 px-lg-14">
                  The result it brings...
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Result
              </h2>
              <p className="mb-6">
                With BytezTech's intervention, Used car selling and buying
                transformed into a well-oiled machine. The owner could now
                effortlessly manage the inventory, track the sales process, and
                get a clear view of the financial aspects with ease. The
                business blossomed, as the owner could now focus on strategic
                growth rather than getting bogged down with administrative
                hassles.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-primary">
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Simplified Operations:{" "}
                  </span>
                  No more chaos; just a smooth, streamlined process that boosted
                  efficiency.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Clear Financial Picture:{" "}
                  </span>
                  With organized data, determining profits and losses became
                  straightforward and quick.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Enhanced Business Growth:{" "}
                  </span>
                  The owner could now focus on scaling the business, armed with
                  insights and clarity, paving the way for greater success and
                  customer satisfaction.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <p className="text-center mb-8">Technology Stack</p>
          <div
            className="swiper-container clients mb-0"
            data-margin="30"
            data-dots="false"
            data-loop="true"
            data-autoplay="true"
            data-autoplaytime="6"
            data-drag="false"
            data-speed="6000"
            data-items-xxl="7"
            data-items-xl="6"
            data-items-lg="5"
            data-items-md="4"
            data-items-xs="2"
          >
            <div className="swiper">
              <div className="swiper-wrapper ticker">
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/php.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/solid/laravel.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/vuejs.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/uiux.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/mysql.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pb-13 pb-md-15">
          <div
            className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-300 mb-14"
            data-image-src="./assets/img/photos/bg16.png"
          >
            <div className="card-body p-10 p-xl-12">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-white mb-3">
                    Book a free consultation
                  </h2>
                  <h3 className="display-3 mb-8 px-lg-8 text-white">
                    Unlock personalized advice with a{" "}
                    <span className="underline-3 style-2 yellow">
                      complimentary consultation.
                    </span>{" "}
                    Book your slot now!
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <span>
                  <ModalButton buttonValue={"Book a free consultation"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container-fluid px-md-6">
          <div
            className="swiper-container swiper-height blog grid-view mb-17 mb-md-19"
            data-margin="30"
            data-nav="true"
            data-dots="true"
            data-items-xxl="2"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Carmela/Carmela1.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Carmela/Carmela2.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Carmela/Carmela3.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
}

export default RoyalCar