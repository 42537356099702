import React from "react";
import { Link } from "react-router-dom";
import Address from "./Address";

const Casestudies = () => {
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container pt-10 pt-md-14">
          <div className="row">
            <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              <h1 className="display-1 mb-3">Projects</h1>
              <p className="lead fs-lg pe-lg-15 pe-xxl-12">
                In-depth analysis of solutions we crafted, showcasing our
                technological prowess.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="grid grid-view projects-masonry">
            <div className="isotope-filter filter mb-10">
              <p>Filter:</p>
              <ul>
                <li>
                  <Link className="filter-item active" data-filter="*">
                    All
                  </Link>
                </li>
                <li>
                  <Link className="filter-item" data-filter=".app">
                    app
                  </Link>
                </li>
                <li>
                  <Link className="filter-item" data-filter=".web">
                    web
                  </Link>
                </li>
                {/* <li>
                  <Link className="filter-item" data-filter=".workshop">
                    Workshop
                  </Link>
                </li>
                <li>
                  <Link className="filter-item" data-filter=".still-life">
                    Still Life
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="row gx-md-8 gy-10 gy-md-13 isotope">
              <div className="project item col-md-6 col-xl-4 app">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/casestudy-seaqua">
                    <img src="./assets/img/casestudy/Seaqua.png" alt="" />
                  </Link>
                  <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                  </figcaption>
                </figure>

                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Beauty product
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/casestudy-seaqua">
                            Seaqua
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Empowering Seaqua with digital innovation: from
                          product listing to seamless commission management,
                          enhancing business growth and user satisfaction.
                        </p>
                        <p classNameName="h6">
                          Technology stack -{" "}
                          <span classNameName="post-content">
                            Flutter, Firebase, Laravel, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 app">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/asset-control">
                    <img
                      src="./assets/img/casestudy/Asset-control.png"
                      alt=""
                    />
                  </Link>
                  <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                  </figcaption>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link
                            to="/asset-control"
                            className="hover"
                            rel="category"
                          >
                            Invetory management
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/asset-control">
                            Asset control
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Discover how BytezTech revolutionized asset management
                          with a barcode-based app, making inventory tracking
                          stress-free and remarkably efficient.
                        </p>
                        <p classNameName="h6">
                          Technology stack -{" "}
                          <span classNameName="post-content">
                            Flutter, Firebase, Laravel, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 web">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/amazing-car-rental">
                    <img src="./assets/img/casestudy/Amaze-car.png" alt="" />
                  </Link>
                  <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                  </figcaption>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Travel and trasporation
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/amazing-car-rental">
                            Amazig car rental
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          Experience ease and transparency in Dubai car rentals
                          with our innovative web solution, setting a new
                          benchmark in customer satisfaction.
                        </p>
                        <p classNameName="h6">
                          Technology stack -{" "}
                          <span classNameName="post-content">
                            Php, Laravel, UI/UX design, MySQL, Google analytics
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project item col-md-6 col-xl-4 web">
                <figure className="lift rounded mb-6 card-img-top overlay overlay-1 hover-scale">
                  <Link to="/royal-car">
                    <img src="./assets/img/casestudy/royal-car.png" alt="" />
                  </Link>
                  <figcaption>
                    <h5 className="from-top mb-0">Read More</h5>
                  </figcaption>
                </figure>
                <div className="project-details d-flex justify-content-center flex-column">
                  <div className="post-header">
                    <div className="card-body">
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            Used car selling
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to="/royal-car">
                            Royal Car
                          </Link>
                        </h2>
                      </div>
                      <div className="post-content">
                        <p>
                          BytezTech revolutionizes Car Mela: from chaotic
                          management to streamlined operations, fostering growth
                          and enhanced customer satisfaction through tailored
                          solutions.
                        </p>
                        <p classNameName="h6">
                          Technology stack -{" "}
                          <span classNameName="post-content">
                            PHP, Laravel, Vue.js, UI/UX design, MySQL
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default Casestudies;
