import React, { useEffect } from "react";
import Address from "../Address";
import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";

const AssetControl = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        data-image-src="./assets/img/casestudy/Asset-control-banner.png"
      >
        <div className="container pt-17 pb-12 pt-md-19 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link to="#" className="text-reset" rel="category">
                    Invetory management
                  </Link>
                </div>
                <h1 className="display-1 mb-3 text-white">
                  Streamlining Asset Management with the "Asset Control App"
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/challenges.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
              The challenge faced by Asset management system is...
              </h2>
            </div>
          </div>
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card me-lg-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">01</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Unorganized Records</h4>
                      <p className="mb-0">
                        Users inundated with myriad products, struggling to find
                        trustworthy and multifaceted solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-lg-13 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">02</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Inefficient Maintenance Scheduling</h4>
                      <p className="mb-0">
                        Maintenance schedules were often missed, leading to
                        unnecessary wear and tear of assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-lg-6 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">03</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Financial Leakages</h4>
                      <p className="mb-0">
                        The inability to track assets accurately sometimes
                        resulted in financial losses due to misplaced or
                        mismanaged assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Challenge
              </h2>
              <p className="lead fs-lg pe-lg-5">
              Efficiently tracking asset status, facilitating repairs, and managing inventory levels.
              </p>
              <p>
                Managing assets in any organization can quickly turn into a
                cumbersome process, especially when the tracking methods are
                antiquated and inefficient. Before the introduction of the Asset
                Control App, many users struggled with keeping accurate records
                of their assets, be it their status, location, or maintenance
                schedules. This not only resulted in a considerable waste of
                time but also increased the likelihood of errors and financial
                losses.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-14 pb-14 pb-md-18">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/solutions.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The solution we offer is ...
              </h2>
            </div>
          </div>

          <div className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center">
            <div className="col-lg-5 offset-lg-1">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/3d2.png"
                  srcSet="./assets/img/illustrations/3d2@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Solution
              </h2>
              <h3 className="display-5 mb-4">
              We bring solutions to make life easier to manage the assets.
              </h3>
              <p className="mb-6">
                Identifying this widespread problem, BytezTech stepped in to
                create a comprehensive solution that would make asset management
                not just easier, but more accurate and efficient. The Asset
                Control App was designed to be a one-stop destination for all
                asset management needs. This app allowed users to catalogue
                their assets meticulously by adding detailed information
                including name, location, and service status, all accessible
                through a barcode assigned to each asset. A feature enabling
                users to update the status of the asset (whether it is under
                repair, sold, or available) with a simple barcode scan, further
                streamlined the process, making asset management a breeze.
              </p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Unified Asset Database</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Proactive Maintenance Alerts</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Barcode Integration</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Customizable Reports</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img
                  className="img-fluid"
                  src="./assets/img/photos/about27.jpg"
                  srcSet="./assets/img/photos/about27@2x.jpg 2x"
                  alt=""
                />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                <div className="card-body py-4 px-5">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <img
                        src="./assets/img/icons/solid/cloud-group.svg"
                        className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="fs-25 counter mb-0 text-nowrap">2000+</h3>
                      <p className="fs-16 lh-sm mb-0 text-nowrap">Happy Users</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card shadow-lg position-absolute text-center d-none d-md-block"
                style={{ bottom: "10%", left: "-10%" }}
              >
                <div className="card-body p-6">
                  <div
                    className="progressbar semi-circle fuchsia mb-3"
                    data-value="85"
                  ></div>
                  <h4 className="mb-0">Time Saved</h4>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                <img
                  src="./assets/img/icons/custom/results.svg"
                  className="svg-inject icon-svg icon-svg-md mb-4"
                  alt=""
                />
                <h2 className="display-4 mb-4 px-lg-14">The result it brings...</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Result
              </h2>
              <p className="mb-6">
                The introduction of the Asset Control App revolutionized the
                asset management sector. The app became an indispensable tool in
                many organizations, allowing them to maintain a seamless log of
                their assets, hence saving time and reducing financial leakages.
                The proactive maintenance alerts significantly increased the
                longevity of assets, contributing to substantial savings in the
                long run. Moreover, users could now handle asset sales and
                services with increased efficiency and transparency, thereby
                fostering trust and reliability.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-primary">
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Time & Financial Savings:{" "}
                  </span>
                  Users noticed a significant reduction in time spent managing
                  assets and a decrease in financial losses.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                  Enhanced Asset Longevity:{" "}
                  </span>
                  Established a harmonious ecosystem where dealers and users
                  thrive together, enhancing product reach and customer
                  satisfaction.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                  Boost in Operational Efficiency:{" "}
                  </span>
                  The organizations using the app witnessed an uptick in operational efficiency, streamlining processes like never before.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <p className="text-center mb-8">Technology Stack</p>
          <div
            className="swiper-container clients mb-0"
            data-margin="30"
            data-dots="false"
            data-loop="true"
            data-autoplay="true"
            data-autoplaytime="6"
            data-drag="false"
            data-speed="6000"
            data-items-xxl="7"
            data-items-xl="6"
            data-items-lg="5"
            data-items-md="4"
            data-items-xs="2"
          >
            <div className="swiper">
              <div className="swiper-wrapper ticker">
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/solid/flutter.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/firebase.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/solid/laravel.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/uiux.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/mysql.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pb-13 pb-md-15">
          <div
            className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-300 mb-14"
            data-image-src="./assets/img/photos/bg16.png"
          >
            <div className="card-body p-10 p-xl-12">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-white mb-3">
                    Book a free consultation
                  </h2>
                  <h3 className="display-3 mb-8 px-lg-8 text-white">
                    Unlock personalized advice with a{" "}
                    <span className="underline-3 style-2 yellow">
                      complimentary consultation.
                    </span>{" "}
                    Book your slot now!
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <span>
                  <ModalButton buttonValue={"Book a free consultation"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container-fluid px-md-6">
          <div
            className="swiper-container swiper-height blog grid-view mb-17 mb-md-19"
            data-margin="30"
            data-nav="true"
            data-dots="true"
            data-items-xxl="2"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Asset-manage/Asset1-min.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Asset-manage/Asset2-min.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/Asset-manage/Asset3-min.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default AssetControl;
