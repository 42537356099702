import React from 'react'
import Whoweare from './career/Whoweare'
import Jobs from './career/Jobs'
import Perks from './career/Perks'

const Career = () => {
  return (
    <>
      <Whoweare />
      <Jobs />
      <Perks />
    </>
  )
}

export default Career