import React from 'react'

const Workinfo = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-5 py-md-16">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="fs-15 text-uppercase text-primary mb-3">Company Facts</h2>
            <h3 className="display-4 pe-xl-15">We are proud of our works</h3>
          </div>
          <div className="col-lg-8">
            <div className="row align-items-center counter-wrapper gy-6 text-center">
              <div className="col-md-4">
                <img src="./assets/img/icons/lineal/check.svg" className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt="" />
                <h3 className="counter">300 <span>+</span></h3>
                <p className="mb-0">Successful Projects</p>
              </div>
              <div className="col-md-4">
                <img src="./assets/img/icons/lineal/user.svg" className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt="" />
                <h3 className="counter">92.3<span>%</span></h3>
                <p className="mb-0">Client Retention Rate</p>
              </div>
              <div className="col-md-4">
                <img src="./assets/img/icons/lineal/briefcase-2.svg" className="svg-inject icon-svg icon-svg-lg text-primary mb-3" alt="" />
                <h3 className="counter">10,000 <span>+</span></h3>
                <p className="mb-0">Hours of Development</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Workinfo