import React from "react";

const Whoweare = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-14 py-md-16">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-lg-6 position-relative order-lg-2">
            <div
              className="shape bg-dot primary rellax w-16 h-20"
              data-rellax-speed="1"
              style={{ top: "3rem", left: "5.5rem" }}
            ></div>
            <div className="overlap-grid overlap-grid-2">
              <div className="item">
                <figure className="rounded shadow">
                  <img
                    src="./assets/img/illustrations/Career1.png"
                    srcSet="./assets/img/illustrations/Career1.png 2x"
                    alt=""
                  />
                </figure>
              </div>
              <div className="item">
                <figure className="rounded shadow">
                  <img
                    src="./assets/img/illustrations/Career.png"
                    srcSet="./assets/img/illustrations/Career.png 2x"
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src="./assets/img/icons/lineal/megaphone.svg"
              className="svg-inject icon-svg icon-svg-md mb-4"
              alt=""
            />
            <h2 className="display-4 mb-3">Career at BytezTech</h2>
            <p className="lead fs-lg">
              At BytezTech, forge a bright future amidst innovation and
              opportunity. Elevate your career, collaborating with experts on
              cutting-edge technology projects.
            </p>
            <p className="mb-6">
              Dive into a culture that prioritizes growth and learning. With us,
              every project is a journey of discovery and innovation.
              Collaborate with dedicated teams who share a passion for
              technological advancements and creating digital marvels.
            </p>
            <div className="row gy-3 gx-xl-8">
              <div className="col-xl-6">
                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                  <li>
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Collaborative environment fostering growth and innovation.
                    </span>
                  </li>
                  <li className="mt-3">
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Work on pioneering projects with industry leaders.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-xl-6">
                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                  
                  <li className="mt-3">
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Experience rewarding recognition and career advancements.
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="uil uil-check"></i>
                    </span>
                    <span>
                      Comprehensive growth opportunities in technology niches.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whoweare;
