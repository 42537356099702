import React from "react";
import { Link } from "react-router-dom";

const Journal = () => {
  return (
    <section className="wrapper bg-gray">
      <div className="container py-14 py-md-16">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0">
          <div className="col-lg-4 mt-lg-2">
            <h2 className="display-4 mb-3">Our Journal</h2>
            <p className="lead fs-lg mb-6 pe-xxl-5">
              Here are the latest news from our blog that got the most
              attention.
            </p>
            <Link to="/blogs" className="btn btn-sm btn-soft-primary rounded">
              View All Blogs
            </Link>
          </div>
          <div className="col-lg-8">
            <div
              className="swiper-container blog grid-view mb-6"
              data-margin="30"
              data-dots="true"
              data-items-md="2"
              data-items-xs="1"
            >
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <article>
                      <figure className="overlay overlay-1 hover-scale rounded mb-5">
                        <Link to={"/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"}>
                          {" "}
                          <img src="./assets/img/blogs/iot/Blog-IoT.webp" alt="" />
                        </Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Read More</h5>
                        </figcaption>
                      </figure>
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                            IoT
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to={"/Simplifying-Life-The-Marriage-of-Smart-Homes-and-IoT"}>
                            Simplifying Life: The Marriage of Smart Homes and
                            IoT
                          </Link>
                        </h2>
                      </div>
                      <div className="post-footer">
                        <ul className="post-meta mb-0">
                          <li className="post-date">
                            <i className="uil uil-calendar-alt"></i>
                            <span>14 Aug 2023</span>
                          </li>
                          <li className="post-comments">
                            <Link to="#">
                              <i className="uil uil-comment"></i>0
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="swiper-slide">
                    <article>
                      <figure className="overlay overlay-1 hover-scale rounded mb-5">
                        <Link to={"/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"}>
                          {" "}
                          <img src="./assets/img/blogs/ai-farming/AI-Agriculture.webp" alt="" />
                        </Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Read More</h5>
                        </figcaption>
                      </figure>
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link to="#" className="hover" rel="category">
                          Technology 
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to={"/AI's-Role-in-Modern-Agriculture-A-New-Dawn-for-Farming"}>
                          AI's Role in Modern Agriculture: A New Dawn for Farming
                          </Link>
                        </h2>
                      </div>
                      <div className="post-footer">
                        <ul className="post-meta mb-0">
                          <li className="post-date">
                            <i className="uil uil-calendar-alt"></i>
                            <span>25 Aug 2023</span>
                          </li>
                          <li className="post-comments">
                            <Link to="#">
                              <i className="uil uil-comment"></i>0
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="swiper-slide">
                    <article>
                      <figure className="overlay overlay-1 hover-scale rounded mb-5">
                        <Link to={"/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"}>
                          {" "}
                          <img src="./assets/img/blogs/nft/NFT-main.webp" alt="" />
                        </Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Read More</h5>
                        </figcaption>
                      </figure>
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link href="#" className="hover" rel="category">
                          Technology
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" to={"/NFTs-Changing-the-Landscape-of-Art-Assets-and-Digital-Ownership"}>
                          NFTs: Changing the Landscape of Art, Assets, and Digital Ownership
                          </Link>
                        </h2>
                      </div>
                      <div className="post-footer">
                        <ul className="post-meta mb-0">
                          <li className="post-date">
                            <i className="uil uil-calendar-alt"></i>
                            <span>04 Sep 2023</span>
                          </li>
                          <li className="post-comments">
                            <Link href="#">
                              <i className="uil uil-comment"></i>0
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  {/* <div className="swiper-slide">
                    <article>
                      <figure className="overlay overlay-1 hover-scale rounded mb-5">
                        <Link href="#">
                          {" "}
                          <img src="./assets/img/photos/b7.jpg" alt="" />
                        </Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Read More</h5>
                        </figcaption>
                      </figure>
                      <div className="post-header">
                        <div className="post-category text-line">
                          <Link href="#" className="hover" rel="category">
                            Business Tips
                          </Link>
                        </div>
                        <h2 className="post-title h3 mt-1 mb-3">
                          <Link className="link-dark" href="#">
                            Morbi leo risus porta eget
                          </Link>
                        </h2>
                      </div>
                      <div className="post-footer">
                        <ul className="post-meta mb-0">
                          <li className="post-date">
                            <i className="uil uil-calendar-alt"></i>
                            <span>7 Jan 2022</span>
                          </li>
                          <li className="post-comments">
                            <Link href="#">
                              <i className="uil uil-comment"></i>2
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Journal;
