import React from 'react'

const Clients = () => {
  return (
    <section className="wrapper bg-gray">
      <div className="container py-14">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-10">
          <div className="col-lg-4 mt-lg-2">
            <h3 className="display-4 mb-3 pe-xxl-5">Trusted by 121+ Clients</h3>
            <p className="lead fs-lg mb-0 pe-xxl-5">Building <span className="underline">lasting partnerships</span> through unparalleled expertise and commitment.</p>
          </div>
          <div className="col-lg-8">
            <div className="row row-cols-2 row-cols-md-4 gx-0 gx-md-8 gx-xl-12 gy-12" data-cues="fadeIn" data-group="clients">
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/Aatmanirbhar.png" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/Asset Logo copy.png" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/Devour now.png" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/mir512_2.png" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/NIION.jpg" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/seaqualogo.webp" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/Stonfixit.png" alt="" /></figure>
              </div>
              <div className="col">
                <figure className="px-3 px-md-0 px-xxl-2" data-cue="fadeIn"><img src="./assets/img/clientslogo/Vgro.png" alt="" /></figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients