import React from 'react'
import { Helmet } from 'react-helmet-async'

const MetaData = () => {
  return (
    <Helmet>
      <title>BytezTech: MVP, IoT, Custom Solutions</title>
      <meta
        name="keywords"
        content="software development company, software company, top software companies, best software, it companies, Softwares, Technology, BytezTech, Flutter, React Native, Php, Android, iOS, Bytez, BytezTechnology, IT Services, Web designer, Website designer in Surat, Web design & development in India, Web development companies in Surat, Surat, Gujarat, India"
      />
      <meta
        name="description"
        content="Explore innovative solutions at BytezTech, your global partner for MVP, IoT, and custom software services. Specialists in Flutter and React Native for cross-platform excellence."
      />
      <link rel="canonical" href="https://www.byteztech.com/" />
      <meta name="city" content="Surat" />
      <meta name="State" content="Gujarat" />
      <meta name="country" content="India" />
      <meta
        property="og:image"
        content="https://www.byteztech.com/assets/img/byteztechlogo2x.png"
      />
      <meta
        property="og:title"
        content="BytezTech | AI | ML | App Development | MVP Development | IoT | OCR | Hybrid App Development | India"
      />
      <meta
        property="og:description"
        content="Explore advanced solutions leveraging the latest technology at BytezTech, your global partner for a diverse range of software services. Our offerings encompass Website Development, Software Development, SaaS, and Mobile App Development, specializing in Flutter and React Native for cross-platform applications."
      />
      <meta property="og:url" content="https://www.byteztech.com" />
      <meta
        name="twitter:title"
        content="BytezTech | AI | ML | App Development | MVP Development | IoT | OCR | Hybrid App Development | India"
      />
      <meta
        name="twitter:description"
        content="Explore advanced solutions leveraging the latest technology at BytezTech, your global partner for a diverse range of software services. Our offerings encompass Website Development, Software Development, SaaS, and Mobile App Development, specializing in Flutter and React Native for cross-platform applications."
      />
      <meta
        name="twitter:image"
        content="https://www.byteztech.com/assets/img/byteztechlogo2x.png"
      />
    </Helmet>
  );
}

export default MetaData