import React, { useEffect } from "react";

const Termsofuse = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });
  return (
    <section className="wrapper bg-light pb-15">
      <div className="container py-14 py-md-14">
        <h2 className="h1 mb-6">Terms and Conditions</h2>
        <div className="row gx-lg-8 gx-xl-12">
          <div className="col-lg-12">
            <div className="blog single">
              <div className="card">
                <div className="card-body">
                  <div className="classic-view">
                    <article className="post">
                      <div className="post-content mb-5">
                        <h3 className="h2 mb-4">Introduction</h3>
                        <p classNameName="mb-8">
                          Welcome to BytezTech. These terms and conditions
                          outline the rules and regulations for the use of
                          BytezTech's Website. By accessing this website, we
                          assume you accept these terms and conditions in full.
                          Do not continue to use BytezTech's website if you do
                          not accept all of the terms and conditions stated on
                          this page.
                        </p>

                        <h3 className="h2 mb-4">License</h3>
                        <p classNameName="mb-8">
                          Unless otherwise stated, BytezTech owns the
                          intellectual property rights for all material on the
                          website. All intellectual property rights are
                          reserved.
                        </p>

                        <h3 className="h2 mb-4">User Responsibilities</h3>
                        <p>
                          <span classNameName="h4">Account Security: </span>Users
                          are responsible for safeguarding their account
                          details, including passwords, and are advised to not
                          disclose them to any third party.
                        </p>
                        <p classNameName="mb-8">
                          <span classNameName="h4">Content Responsibility: </span>
                          Users are responsible for the content they post on
                          this website and should ensure it does not violate any
                          applicable laws or rights of others.
                        </p>

                        <h3 className="h2 mb-4">Privacy Policy</h3>
                        <p classNameName="mb-8">
                          Our Privacy Policy, incorporated by reference in these
                          Terms and Conditions, sets forth how we will use
                          personal information you provide to us. By using this
                          Website, you agree to be bound by our Privacy Policy.
                        </p>

                        <h3 className="h2 mb-4">Links to Other Websites</h3>
                        <p classNameName="mb-8">
                          Our service may contain links to third-party websites
                          or services that are not owned or controlled by
                          BytezTech. BytezTech has no control over, and assumes
                          no responsibility for, the content, privacy policies,
                          or practices of any third-party websites or services.
                        </p>

                        <h3 className="h2 mb-4">Limitations of Liability</h3>
                        <p classNameName="mb-8">
                        BytezTech will not be liable to you (whether under the law of contract, the law of torts, or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website for any indirect, special or consequential loss.
                        </p>

                        <h3 className="h2 mb-4">Governing Law</h3>
                        <p classNameName="mb-8">
                        These terms and conditions will be governed by and interpreted in accordance with the laws of [Your Jurisdiction], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your Jurisdiction] for the resolution of any disputes.
                        </p>

                        <h3 className="h2 mb-4">Changes to Terms and Conditions</h3>
                        <p classNameName="mb-8">
                        We reserve the right to amend or modify these Terms and Conditions at any time, and it is your responsibility to review these Terms and Conditions periodically.
                        </p>

                        <h3 className="h2 mb-4">Contact Us</h3>
                        <p>
                        If you have any questions about these Terms and Conditions, please contact us at {" "}
                        <a href="mailto:#">Info@byteztech.in</a>
                        </p>

                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Termsofuse;
