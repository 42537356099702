import React from "react";

const Perks = () => {
  return (
    <section className="wrapper pb-15">
      <div className="container py-14 py-md-16">
        <div className="row text-center">
          <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto position-relative">
            <img
              src="./assets/img/svg/doodle3.svg"
              className="h-11 position-absolute d-none d-lg-block"
              style={{top: "-45%", left: "23%"}}
              alt=""
            />
            <img
              src="./assets/img/svg/doodle4.svg"
              className="h-8 position-absolute d-none d-lg-block"
              style={{top: "6%", right: "2%"}}
              alt=""
            />
            <h2 className="fs-16 text-uppercase text-muted mb-3">
              Perks & Benefits
            </h2>
            <h3 className="display-3 mb-11 px-lg-5 px-xl-0 px-xxl-3">
            Discover unmatched perks and benefits at BytezTech, where{" "}
              <span className="text-gradient gradient-7">your growth</span> is our priority
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-11 mx-auto">
            <div className="row gx-md-8 gy-10 text-center">
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-yellow rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/comsalary.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Competitive Salary</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-green rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/skilldev.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Skill Development</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-purple rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/worklife.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Work-Life Balance</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-pink rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/incult.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Inclusive Culture</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-violet rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/techexpo.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Technological Exposure</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-orange rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/innoenv.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Innovative Environment</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-blue rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/healthben.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Health Benefits</h4>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="svg-bg svg-bg-lg bg-pale-leaf rounded-xl mb-4">
                  {" "}
                  <img
                    src="./assets/img/icons/custom/emprecog.svg"
                    className="svg-inject icon-svg solid text-navy"
                    alt=""
                  />{" "}
                </div>
                <h4 className="fs-20">Employee Recognition</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Perks;
