import React, { useEffect } from "react";
import Hiwabout from "./Hiwabout";
import Address from "./Address";
import { Helmet } from "react-helmet-async";

const About = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>BytezTech: MVP, IoT, Custom Solutions</title>
      </Helmet>
      <section className="wrapper">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center mb-10 mb-md-12">
            <div className="col-lg-6">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-6">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/about-topleft.png"
                      srcSet="./assets/img/illustrations/about-topleft.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
                <div className="col-md-6 align-self-end">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/about-topright.png"
                      srcSet="./assets/img/illustrations/about-topright.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
                <div className="col-12">
                  <figure className="rounded mx-md-5">
                    <img
                      src="./assets/img/illustrations/about-bottom.png"
                      srcSet="./assets/img/illustrations/about-bottom.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-primary mb-3">
                Who Are We?
              </h2>
              <h3 className="display-3 mb-5">
                Company driven by creative strategies to propel our clients'
                goals and boost their revenue.
              </h3>
              <p className="mb-6">
                At the heart of our approach lies a singular focus: our clients
                and their success. Leveraging innovative strategies, we tailor
                solutions to align seamlessly with your unique goals. Every
                project undertaken is a commitment to elevate your brand,
                ensuring measurable growth. Our expertise translates into
                real-world revenue boosts, positioning you at the forefront of
                your industry. Partner with us, and let's co-create a legacy of
                achievement.
              </p>
              <div className="row align-items-center counter-wrapper gy-6">
                <div className="col-md-6">
                  <h3 className="counter counter-lg mb-1">95.7%</h3>
                  <h5 className="mb-0">Customer Satisfaction</h5>
                </div>
                <div className="col-md-6">
                  <h3 className="counter counter-lg mb-1">5x</h3>
                  <h5 className="mb-0">Revenue Growth</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6 mb-7 mb-md-9">
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">1</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Vision</h3>
                  <p className="mb-2">
                    To fuel digital change, we empower businesses to flourish,
                    innovate, and redefine success. Imagine a future where
                    technology is a dynamic force in the hands of visionaries,
                    shaping a brilliantly transformed tomorrow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">2</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Mission</h3>
                  <p className="mb-2">
                    To connect your innovative ideas with tangible outcomes, we
                    propel your digital transformation. Our focus is on
                    developing solutions that align with market needs, fuel
                    revenue growth, leave a lasting impression, and redefine
                    your industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-block pe-none btn-soft-primary me-4 w-10 h-10">
                    {" "}
                    <span className="number fs-18">3</span>{" "}
                  </div>
                </div>
                <div>
                  <h3>Our Values</h3>
                  <p className="mb-2">
                    Integrity and authenticity guide our every step, ensuring
                    excellence and commitment in all our endeavors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Hiwabout />
      <Address />
    </>
  );
};

export default About;
