import React from "react";
import { Link } from "react-router-dom";


const Jobs = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-14 py-md-16">
        <div className="row text-center">
          <div className="col-xl-10 mx-auto">
            <h2 className="fs-15 text-uppercase text-muted mb-3">Job Positions</h2>
            <h3 className="display-4 mb-10 px-xxl-15">
              We’re always searching for amazing people to join our team. Take a
              look at our current openings.
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="job-list mb-10">
              <h3 className="mb-4">Design</h3>
              <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-red text-white w-9 h-9 fs-17 me-3">
                        GD
                      </span>{" "}
                      Senior Graphic Designer  (1+ Yr)
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Surat{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link>
              <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-green text-white w-9 h-9 fs-17 me-3">
                        UX
                      </span>{" "}
                      UI/UX Designer  (1+ Yr)
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Surat{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link>
              {/* <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-yellow text-white w-9 h-9 fs-17 me-3">
                        AN
                      </span>{" "}
                      Multimedia Artist &amp; Animator{" "}
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Birmingham, UK{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link> */}
            </div>
            <div className="job-list">
              <h3 className="mb-4">Development</h3>
              <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-purple text-white w-9 h-9 fs-17 me-3">
                        FS
                      </span>{" "}
                      MERN Stack Developer  (1+ Yr)
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full-time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Surat{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link>
              <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-orange text-white w-9 h-9 fs-17 me-3">
                        FD
                      </span>{" "}
                      Flutter Developer  (2+ Yr)
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full-time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Surat{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link>
              <Link to={"#"} className="card mb-4 lift">
                <div className="card-body p-5">
                  <span className="row justify-content-between align-items-center">
                    <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                      <span className="avatar bg-pink text-white w-9 h-9 fs-17 me-3">
                        PD
                      </span>{" "}
                      Python Developer  (1+ Yr)
                    </span>
                    <span className="col-5 col-md-3 text-body d-flex align-items-center">
                      <i className="uil uil-clock me-1"></i> Full time{" "}
                    </span>
                    <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                      <i className="uil uil-location-arrow me-1"></i> Surat{" "}
                    </span>
                    <span className="d-none d-lg-block col-1 text-center text-body">
                      <i className="uil uil-angle-right-b"></i>
                    </span>
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jobs;
