import React from "react";
import Address from "../Address";
import Tech from "../service/Tech";

import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";

const TravelAppSingaporeTampines = () => {
  return (
    <>
      <section className="wrapper">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center mb-10 mb-md-12">
            <div className="col-lg-6">
              <h1 className="fs-16">Travel app Singapore Tampines</h1>

              <h3 className="display-3 mb-5">
                Choose BytezTech travel app to explore tampines in Singapore
              </h3>
              <p className="mb-6">
                If you are in Tampines Singapore and you want to explore more
                about the city choose us to guide you through the streets of
                Tampines and uncover its beauty.
              </p>
              <p className="mb-6">
                With us you can know about inspirations about popular tourist
                attractions, travel tips, to the best accommodation in Singapore
                here
              </p>
              <p className="mb-6">
                We can help you to discover Singapore's east with ease. We help
                you to discover whether you are travelling for the first time or
                you are a local, this app can have all of the necessary
                solutions when it comes to exploring everything in Tampines.
              </p>
              <p className="mb-6">
                BytezTech has been a leading innovator in travel tech as well.
                We provide a unique user experience which can help you explore
                Tampines with the travel app which will be designed to enrich
                your experience.
              </p>

              <Link
                to={"/application-development"}
                className="btn btn-sm btn-primary rounded"
              >
                Hire us
              </Link>
              <ModalButton buttonValue={"Get a free quote"} />
            </div>
            <div className="col-lg-6">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-12">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/why-choose-us2.png"
                      srcSet="./assets/img/illustrations/why-choose-us2.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-lg-8 gx-xl-12 gy-6 mb-7 mb-md-9">
            <div className="col-lg-12">
              <div className="d-flex flex-row">
                <section id="terms-conditions" class="wrapper">
                  <h3 className="display-3 mb-5">Why choose BytezTech?</h3>
                  <p>
                    We provide experienced engineers to work on the travel app
                    which can enhance your travel experience and can be
                    considered as the best way to travel with the help of
                    technology.
                  </p>
                  <p>
                    With a focus on user-friendly design, rich content, and
                    cutting-edge technology, TravelEasy has developed a range of
                    apps that cater to the needs of modern travellers. Their
                    latest venture, **Discover Tampines**, continues this
                    tradition of excellence, offering an unparalleled guide to
                    one of Singapore's most dynamic neighbourhoods.
                  </p>
                  <p class="display-6">Features of the travel app:</p>
                  <h4 class="mb-0">1. Comprehensive Interactive Map</h4>
                  <p>
                    We provide the best comprehensive maps which have all of the
                    highlights of key attractions,dining spots, shopping
                    centres, and many other things which you can look for in
                    Tampines.
                  </p>

                  <h4 class="mb-0">2. Customizable Itineraries</h4>
                  <p>
                    We can also provide you specially curated personalised
                    travel plans which can have your day planned and helps you
                    to plan a family day out, a shopping spree, or a nature
                    walk,{" "}
                  </p>

                  <h4 class="mb-0">3. User Reviews and Recommendations</h4>
                  <p>
                    We provide honest reviews and ratings from the past visitors
                    of the place so that you can have an idea of the place. You
                    can also share your experiences which will help other
                    visitors and keep them informed.
                  </p>

                  <h4 class="mb-0">4. Integrated Booking Services</h4>
                  <p>
                    We also provide integrated booking services with the
                    application which can enhance your travel experience and
                    provide an ease to other reservations, and access essential
                    services directly through the app.{" "}
                  </p>
                </section>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row gx-md-5 gy-5">
                <div className="col-12">
                  <figure className="rounded mx-md-5">
                    <img
                      src="./assets/img/illustrations/project-web-4.png"
                      srcSet="./assets/img/illustrations/project-web-4.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex flex-row">
                <section id="terms-conditions" class="wrapper">
                  <h3 className="display-3 mb-5">Why choose tampines?</h3>

                  <p>
                    Tampines is an energetic region offering an interesting mix
                    of metropolitan conveniences and regular magnificence.
                  </p>
                  <p>
                    With significant malls like Tampines Shopping centre,
                    beautiful parks like Tampines Eco Green, and local area
                    centres like the Tampines Center, you can get the best from
                    the city if you have perfect knowledge about the local.
                  </p>
                </section>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="d-flex flex-row">
                <section id="terms-conditions" class="wrapper">
                  <h3 className="display-3 mb-5">Conclusion</h3>
                  <p>
                    We have a team of experts who are dedicated to making travel
                    easier, more enjoyable, and more accessible. We provide a
                    team of our expertise and passion for travel to one of
                    Singapore’s most exciting districts.
                  </p>
                  <p>
                    We provide the best designs with the user in mind, and helps
                    you to create a better design experience for the users
                    having all of the information and tools which can be needed
                    to make your travel easier.{" "}
                  </p>
                  <p>
                    Choose Bytezech travel app Singapore tampines to ease your
                    travel experience. You will be embarked on an unforgettable
                    journey through one of Singapore’s most dynamic and
                    culturally rich districts. Choose us to be your guide and we
                    will ensure that you have an enriching travel experience
                    with us.
                  </p>
                </section>
              </div>
              <ModalButton buttonValue={"Convinced yet?"} />
            </div>
          </div>
        </div>
      </section>
      <Tech />
      <Address />
    </>
  );
};

export default TravelAppSingaporeTampines;
