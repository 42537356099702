import React from "react";
import { Link } from "react-router-dom";
// import Swiper from "swiper";
import "swiper/css"

const Ourprojects = () => {

  return (
    <section className="wrapper bg-light">
      <div className="overflow-hidden">
        <div className="container pt-12 pt-lg-7 pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
              <h2 className="fs-16 text-uppercase text-primary mb-3">
                Latest Projects
              </h2>
              <h3 className="display-3 mb-10">
                Explore Our Premium Mobile & Web App Portfolios: Excellence in
                Every Delivery.
              </h3>
            </div>
          </div>
          <div
            className="swiper-container swiper-height grid-view nav-bottom nav-color mb-14"
            data-margin="30"
            data-dots="false"
            data-nav="true"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper overflow-visible">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded mb-7">
                    <Link to="#">
                      <img
                        src="./assets/img/illustrations/portfolio-1.webp"
                        srcSet="./assets/img/illustrations/portfolio-1.webp 2x"
                        alt=""
                      />
                    </Link>
                  </figure>
                  <div className="project-details d-flex justify-content-center flex-column">
                    <div className="post-header">
                      <h2 className="post-title h3">
                        <Link to="#" className="link-dark">
                        Seaqua
                        </Link>
                      </h2>
                      <div className="post-category text-ash">
                      Mobile app
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded mb-7">
                    <Link to="#">
                      <img
                        src="./assets/img/illustrations/portfolio-2.webp"
                        srcSet="./assets/img/illustrations/portfolio-2.webp 2x"
                        alt=""
                      />
                    </Link>
                  </figure>
                  <div className="project-details d-flex justify-content-center flex-column">
                    <div className="post-header">
                      <h2 className="post-title h3">
                        <Link to="#" className="link-dark">
                        Amazing Car
                        </Link>
                      </h2>
                      <div className="post-category text-ash">Web development</div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded mb-7">
                    <Link to="#">
                      <img
                        src="./assets/img/illustrations/portfolio-3.webp"
                        srcSet="./assets/img/illustrations/portfolio-3.webp 2x"
                        alt=""
                      />
                    </Link>
                  </figure>
                  <div className="project-details d-flex justify-content-center flex-column">
                    <div className="post-header">
                      <h2 className="post-title h3">
                        <Link to="#" className="link-dark">
                        NIION
                        </Link>
                      </h2>
                      <div className="post-category text-ash">
                        Mobile App
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded mb-7">
                    <Link to="#">
                      <img
                        src="./assets/img/illustrations/portfolio-4.webp"
                        srcSet="./assets/img/illustrations/portfolio-4.webp 2x"
                        alt=""
                      />
                    </Link>
                  </figure>
                  <div className="project-details d-flex justify-content-center flex-column">
                    <div className="post-header">
                      <h2 className="post-title h3">
                        <Link to="#" className="link-dark">
                        The Aatmanirbhar Bharat
                        </Link>
                      </h2>
                      <div className="post-category text-ash">Web Development</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ourprojects;
