import React, { useEffect, useRef } from "react";
import Headhesive from "headhesive";
import { Link } from "react-router-dom";

const Header = () => {
  const navbarRef = useRef(null);
  const mobileScreen = window.matchMedia("(max-width: 575px)");
  console.log(mobileScreen);

  useEffect(() => {
    const options = {
      offset: 350,
      offsetSide: "top",
      classes: {
        clone: "navbar-clone fixed",
        stick: "navbar-stick",
        unstick: "navbar-unstick",
      },
      onStick: function () {
        const navbarClonedClass = this.clonedElem.classList;
        if (
          navbarClonedClass.contains("transparent") &&
          navbarClonedClass.contains("navbar-dark")
        ) {
          this.clonedElem.classList.replace("navbar-dark", "navbar-light");
        }
      },
    };

    const banner = new Headhesive(navbarRef.current, options);

    return () => {
      banner.destroy();
    };
  }, []);
  return (
    <header className="wrapper bg-light">
      <nav ref={navbarRef} className="navbar navbar-expand-lg classic transparent navbar-light">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <Link to={"/"}>
              <img
                src="./assets/img/byteztechlogo.png"
                srcSet="./assets/img/byteztechlogo2x.png 2x"
                alt="byteztech_logo"
                title="byteztech_logo"
              />
            </Link>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">BytezTech</h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                  <Link className="nav-link " to={"/travel-app-singapore-tampines"}>
                  travelAppSingaporeTampines
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link " to={"/about"}>
                    About
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  {/* <Link
                    className="nav-link dropdown-toggle"
                    to={"/"}
                    data-bs-toggle="dropdown"
                  >
                    Service
                  </Link> */}
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                    Service
                  </a>
                  <div className="dropdown-menu dropdown-lg">
                    <div className="dropdown-lg-content">
                      <div>
                        <h6 className="dropdown-header">Mobile App Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              Android app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              iOS app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              Flutter app
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/mobile-app-solution"}>
                              React Native app
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h6 className="dropdown-header">Web Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              MERN stack
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Python development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Laravel development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-solution"}>
                              Magento website
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h6 className="dropdown-header">Custom Solution</h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              SaaS development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              IoT based solution
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              Chatbot development
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/custom-solution"}>
                              Live streaming solution
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to={"/"} data-bs-toggle="dropdown">
                    Hire Team
                  </Link>
                  <div className="dropdown-menu dropdown-lg">
                    <div className="dropdown-lg-content">
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <Link className="dropdown-item" to={"/application-development"}>
                              Mobile App Developer
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/web-development"}>
                              Web Developer
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to={"/career"}>
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={"/casestudies"}>
                    Case Studies
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link " to={"/blogs"}>
                    Blog
                  </Link>
                </li>
                <li
                  className="nav-item"
                  style={{ display: mobileScreen.matches ? "list-item" : "none" }}
                >
                  <Link className="nav-link " to={"/contact"}>
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <Link href="mailto:info@byteztech.in" className="link-inverse">
                    info@byteztech.in
                  </Link>
                  <br /> +91 635 1100 121 <br />
                  <nav className="nav social social-white mt-4">
                    <Link href="#">
                      <i className="uil uil-twitter"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-facebook-f"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-dribbble"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-instagram"></i>
                    </Link>
                    <Link href="#">
                      <i className="uil uil-youtube"></i>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-other ms-lg-4">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-none d-md-block">
                <Link to={"/contact"} className="btn btn-sm btn-primary rounded">
                  Contact
                </Link>
              </li>
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn">
                  <span></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
