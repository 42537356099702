import React, { useEffect } from "react";
import Address from "../Address";
import ModalButton from "../reactModal/ModalButton";
import { Link } from "react-router-dom";

const CasestudySeaqua = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: "smooth",
    });
  });
  return (
    <>
      <section
        className="wrapper image-wrapper bg-image bg-overlay text-white"
        data-image-src="./assets/img/casestudy/Seaquacover0.png"
      >
        <div className="container pt-17 pb-12 pt-md-19 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="post-header">
                <div className="post-category text-line text-white">
                  <Link to="#" className="text-reset" rel="category">
                    Beauty Product
                  </Link>
                </div>
                <h1 className="display-1 mb-3 text-white">
                  Revolutionizing Beauty and Healthcare Through a Singular
                  Platform
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/challenges.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The challenge faced by Seaqua's product is...
              </h2>
            </div>
          </div>
          <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="card me-lg-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">01</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Oversaturated Market</h4>
                      <p className="mb-0">
                        Users inundated with myriad products, struggling to find
                        trustworthy and multifaceted solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ms-lg-13 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">02</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Fragmented Distribution Channels</h4>
                      <p className="mb-0">
                        Existing dealer and user connections lacked transparency
                        and incentives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mx-lg-6 mt-6">
                <div className="card-body p-6">
                  <div className="d-flex flex-row">
                    <div>
                      <span className="icon btn btn-circle btn-lg btn-soft-primary pe-none me-4">
                        <span className="number">03</span>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-1">Multi-faceted Concerns</h4>
                      <p className="mb-0">
                        A need for a product addressing various health and
                        beauty concerns, including hair fall and dandruff
                        prevention, and proactive measures against breast
                        cancer, without side effects.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Challenge
              </h2>
              <p className="lead fs-lg pe-lg-5">
                Managing diverse beauty products, tracking commissions, and
                user-dealer connections.
              </p>
              <p>
                In an overly saturated market of beauty and healthcare products,
                individuals often struggle to find solutions that address a
                myriad of concerns including hair fall, dandruff, and proactive
                measures against breast cancer. Moreover, the challenge was to
                bring forth a product, conceived by an experienced pharmacist,
                to the market in a manner that it creates a win-win situation
                for both the dealers and the users. The market needed a
                transparent and rewarding system that could connect dealers with
                customers seamlessly, encouraging growth and fostering trust.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-14 pb-14 pb-md-18">
          <div className="row mb-5">
            <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
              <img
                src="./assets/img/icons/custom/solutions.svg"
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-4 px-lg-14">
                The solution we offer is ...
              </h2>
            </div>
          </div>

          <div className="row gx-3 gy-10 mb-15 mb-md-18 align-items-center">
            <div className="col-lg-5 offset-lg-1">
              <figure>
                <img
                  className="w-auto"
                  src="./assets/img/illustrations/3d2.png"
                  srcSet="./assets/img/illustrations/3d2@2x.png 2x"
                  alt=""
                />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Solution
              </h2>
              <h3 className="display-5 mb-4">
                We bring solutions to make life easier for seaqua customers.
              </h3>
              <p className="mb-6">
                We developed the Seaqua application, a unified platform that not
                only showcases a range of revolutionary beauty products
                formulated for both men and women but also integrates a
                streamlined process for dealers and users to connect and conduct
                business. This digital solution houses features like product
                listings, advanced search and filter options, and a cart system,
                facilitating easy navigation and purchase processes for users.
              </p>
              <p className="mb-6">
                From a business perspective, it introduced a referral system,
                allowing various dealers to add users through references,
                fostering a community of trust and mutual growth. Moreover, to
                incentivize the dealers, we embedded a commission management
                system that grants them a flat commission on every user purchase
                made through their reference, ensuring a continuous and
                symbiotic business relationship.
              </p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Unified Platform</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Streamlined Business Processes</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Community Building with Incentives</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Dealer Incentivization</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-8 gy-10 align-items-center">
            <div className="col-lg-6 offset-lg-1 order-lg-2 position-relative">
              <figure className="rounded">
                <img
                  className="img-fluid"
                  src="./assets/img/photos/about27.jpg"
                  srcSet="./assets/img/photos/about27@2x.jpg 2x"
                  alt=""
                />
              </figure>
              <div
                className="card shadow-lg position-absolute d-none d-md-block"
                style={{ top: "15%", left: "-7%" }}
              >
                <div className="card-body py-4 px-5">
                  <div className="d-flex flex-row align-items-center">
                    <div>
                      <img
                        src="./assets/img/icons/solid/cloud-group.svg"
                        className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="fs-25 counter mb-0 text-nowrap">176+</h3>
                      <p className="fs-16 lh-sm mb-0 text-nowrap">
                        Happy Dealers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card shadow-lg position-absolute text-center d-none d-md-block"
                style={{ bottom: "10%", left: "-10%" }}
              >
                <div className="card-body p-6">
                  <div
                    className="progressbar semi-circle fuchsia mb-3"
                    data-value="85"
                  ></div>
                  <h4 className="mb-0">Time Saved</h4>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                <img
                  src="./assets/img/icons/custom/results.svg"
                  className="svg-inject icon-svg icon-svg-md mb-4"
                  alt=""
                />
                <h2 className="display-4 mb-4 px-lg-14">
                  The result it brings...
                </h2>
              </div>
            </div>
            <div className="col-lg-5">
              <h2 className="fs-16 text-uppercase text-gradient gradient-0 mb-3">
                The Result
              </h2>
              <p className="mb-6">
                The Seaqua app has transformed the traditional beauty and
                healthcare market, providing a user-friendly platform for
                diverse health and beauty concerns. Its intuitive design ensures
                users find reliable solutions without worrying about side
                effects. The introduction of a referral and commission system
                propelled the product's reach, fostering a community of
                satisfied users and profitable dealers. This resulted in
                exponential growth in product sales and an expanding user base,
                demonstrating the powerful synergy of technology with healthcare
                and beauty. Seaqua has set a new industry standard, delivering
                outstanding results and unparalleled customer satisfaction.
              </p>
              <ul className="icon-list bullet-bg bullet-soft-primary">
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Exponential Growth in Sales:{" "}
                  </span>
                  The app fueled a substantial increase in product sales,
                  showcasing the demand and effectiveness of the products
                  offered.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Symbiotic Dealer-User Relationship:{" "}
                  </span>
                  Established a harmonious ecosystem where dealers and users
                  thrive together, enhancing product reach and customer
                  satisfaction.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Revolutionary Market Standard:{" "}
                  </span>
                  Set a new industry benchmark by effectively merging technology
                  with healthcare and beauty, fostering a community of satisfied
                  users and profitable dealers.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Positive Health Impacts:{" "}
                  </span>
                  Users have reported significant improvements in their health
                  and beauty concerns, thanks to the product's multifaceted
                  benefits.
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span classNameName="result-span">
                    Client Attraction and Retention:{" "}
                  </span>
                  The success story of Seaqua has become a beacon, attracting
                  other clients looking to revolutionize their business models
                  and achieve similar successes.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <p className="text-center mb-8">Technology Stack</p>
          <div
            className="swiper-container clients mb-0"
            data-margin="30"
            data-dots="false"
            data-loop="true"
            data-autoplay="true"
            data-autoplaytime="6"
            data-drag="false"
            data-speed="6000"
            data-items-xxl="7"
            data-items-xl="6"
            data-items-lg="5"
            data-items-md="4"
            data-items-xs="2"
          >
            <div className="swiper">
              <div className="swiper-wrapper ticker">
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/solid/flutter.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/firebase.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/solid/laravel.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/uiux.svg"
                    alt=""
                  />
                </div>
                <div className="swiper-slide px-5">
                  <img
                    classNameName="svg-inject icon-svg icon-svg-md"
                    src="./assets/img/icons/custom/mysql.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pb-13 pb-md-15">
          <div
            className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-300 mb-14"
            data-image-src="./assets/img/photos/bg16.png"
          >
            <div className="card-body p-10 p-xl-12">
              <div className="row text-center">
                <div className="col-xl-11 col-xxl-9 mx-auto">
                  <h2 className="fs-16 text-uppercase text-white mb-3">
                    Book a free consultation
                  </h2>
                  <h3 className="display-3 mb-8 px-lg-8 text-white">
                    Unlock personalized advice with a{" "}
                    <span className="underline-3 style-2 yellow">
                      complimentary consultation.
                    </span>{" "}
                    Book your slot now!
                  </h3>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <span>
                  <ModalButton buttonValue={"Book a free consultation"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container-fluid px-md-6">
          <div
            className="swiper-container swiper-height blog grid-view mb-17 mb-md-19"
            data-margin="30"
            data-nav="true"
            data-dots="true"
            data-items-xxl="2"
            data-items-md="2"
            data-items-xs="1"
          >
            <div className="swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/seaqua-pics/Seaqua1.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/seaqua-pics/Seaqua2.png" alt="" />
                  </figure>
                </div>
                <div className="swiper-slide">
                  <figure className="rounded">
                    <img src="./assets/img/seaqua-pics/Seaqua3.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Address />
    </>
  );
};

export default CasestudySeaqua;
