import React, { useState } from "react";
import ReactModal from "./ReactModal";

const ModalButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const buttonStyles =
    props.buttonValue === "Get a free quote" ? { marginLeft: "15px" } : {};
  return (
    <>
      <button
        style={buttonStyles}
        className={props.buttonValue === "Book a free consultation" ? "btn btn-white rounded" : "btn btn-sm btn-outline-primary rounded"}
        onClick={openModal}
      >
        {props.buttonValue}
      </button>
      <ReactModal show={showModal} onClose={closeModal} />
    </>
  );
};

export default ModalButton;
