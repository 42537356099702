import React from "react";
import Address from "../Address";
import Tech from "../service/Tech";
import { Link } from "react-router-dom";
import ModalButton from "../reactModal/ModalButton";
import { Helmet } from "react-helmet-async";

const TravelExperienceSingapore = () => {
  return (
    <>
      <Helmet>
        <title>Travel App Singapore Tampines - Discover with BytezTech</title>
        <meta
          name="description"
          content="Travel app Singapore Tampines by BytezTech: Navigate local attractions, discover hidden gems, and enjoy a seamless travel experience with our intuitive app designed for Singapore travelers."
        />
        <meta name="keywords" content="Travel app Singapore Tampines" />
        <meta
          property="og:image"
          content="https://www.byteztech.com/assets/img/illustrations/why-choose-us2.png"
        />
        <meta
          property="og:title"
          content="Travel App Singapore Tampines - Discover with BytezTech"
        />
        <meta
          name="og:description"
          content="Travel app Singapore Tampines by BytezTech: Navigate local attractions, discover hidden gems, and enjoy a seamless travel experience with our intuitive app designed for Singapore travelers."
        />
        <meta
          property="og:url"
          content="https://byteztech.com/travel-experience-singapore"
        />
        <link
          rel="canonical"
          href="https://byteztech.com/travel-experience-singapore"
        />
        <meta
          name="twitter:title"
          content="Travel App Singapore Tampines - Discover with BytezTech"
        />
        <meta
          name="twitter:description"
          content="Travel app Singapore Tampines by BytezTech: Navigate local attractions, discover hidden gems, and enjoy a seamless travel experience with our intuitive app designed for Singapore travelers."
        />
        <meta
          name="twitter:image"
          content="https://www.byteztech.com/assets/img/illustrations/why-choose-us2.png"
        />
      </Helmet>
      <section className="wrapper">
        <div className="container py-14 py-md-16">
          <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center mb-10 mb-md-12">
            <div className="col-lg-6">
              <h1 className="fs-16">Travel experience Singapore</h1>

              <h3 className="display-3 mb-5">
                Choose BytezTech For Travel app Singapore Tampines for best
                travel experience
              </h3>
              <p className="mb-6">
                If your company wants to develop a unique travel app in Tampines
                Singapore which can help users to travel and create memories
                then choose us to design your customised Travel app Singapore
                Tampines to guide you through the streets of Tampines and
                uncover its beauty.
              </p>
              <p className="mb-6">
                With choosing us to develop your Travel app Singapore Tampines
                you can know about inspirations and various designs comprising
                from popular tourist attractions, travel tips, to the best
                accommodation in Singapore here
              </p>
              <p className="mb-6">
                We can help you to discover Singapore's east with ease. We help
                you to discover whether you are travelling for the first time or
                you are a local, this app can have all of the necessary
                solutions when it comes to exploring everything in Tampines.
              </p>
              <p className="mb-6">
                We have been a leading innovator in travel tech. We have skilled
                technical engineers who can help you to develop your travel
                application easily. We provide a unique user experience which
                can help you explore Tampines with the travel app which will be
                designed to enrich your experience.
              </p>

              <Link
                to={"/application-development"}
                className="btn btn-sm btn-primary rounded"
              >
                Hire us
              </Link>
              <ModalButton buttonValue={"Get a free quote"} />
            </div>
            <div className="col-lg-6">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-12">
                  <figure className="rounded">
                    <img
                      src="./assets/img/illustrations/why-choose-us2.png"
                      srcSet="./assets/img/illustrations/why-choose-us2.png 2x"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-lg-8 gx-xl-12 gy-6 mb-7 mb-md-9">
            <div className="col-lg-12">
              <div className="d-flex flex-row">
                <section id="terms-conditions" class="wrapper">
                  <h3 className="display-3 mb-5">Why choose BytezTech?</h3>

                  <p>
                    We provide experienced engineers to work on the travel app
                    which can enhance your travel experience and can be
                    considered as the best way to travel with the help of
                    technology.
                  </p>

                  <p>
                    With a focus on user-friendly design, rich content, and
                    cutting-edge technology, we have developed a range of
                    applications which is specifically designed for modern
                    travellers.
                  </p>
                  <h4 class="mb-0">1. Pre planned goals:</h4>
                  <p>
                    we focus on your pre planned key goals including improving
                    local tourism with supporting local businesses, and
                    promoting the cultural heritage.
                  </p>

                  <h4 class="mb-0">2. Collaboration with Local Entities:</h4>
                  <p>
                    we have involved various local entities which can provide a
                    better user experience to our clients and development
                    process with various stakeholders.
                  </p>

                  <h4 class="mb-0">3. Our Development Approach:</h4>
                  <p>
                    Adopting an agile methodology allowed the development team
                    to work in sprints, making iterative improvements based on
                    ongoing user feedback and testing.
                  </p>

                  <h4 class="mb-0">4. Flexibility of the Design:</h4>
                  <p>
                    we provide flexible design changes and promote error free
                    work with the help technological advancements.
                  </p>

                  <h4 class="mb-0">5. User-Centric Design:</h4>
                  <p>
                    we provide a team of skilled and experienced people in the
                    field of creating and designing a unique application
                    according to your needs
                  </p>
                </section>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="d-flex flex-row">
                <section id="terms-conditions" class="wrapper">
                  <h3 className="display-3 mb-5">Conclusion</h3>
                  <p>
                    BytezTech provides a team of experts who are dedicated to
                    develop easier, and make your application more accessible.
                    We provide a team of our expertise and passion for travel to
                    one of Singapore’s most exciting districts.
                  </p>
                  <p>
                    We provide the best designs with the user in mind, and help
                    you to create a better design experience for the users
                    having all of the information and tools which can be needed
                    to make your travel easier.
                  </p>
                  <p>
                    Choose Bytezech to develop your travel app Singapore
                    tampines smoothly via perfectly skilled technology,
                  </p>
                  <p>
                    Choose us to be your guide and we will ensure that you have
                    an enriching travel experience with us.
                  </p>
                </section>
              </div>
              <ModalButton buttonValue={"Convinced yet?"} />
            </div>
          </div>
        </div>
      </section>
      <Tech />
      <Address />
    </>
  );
};

export default TravelExperienceSingapore;
