import React from 'react'

const Reviews = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-16 py-md-18">
        <div className="card shadow-lg mt-n21 mt-md-n23">
          <div className="row gx-0">
            <div className="container py-5">
              <div className="row gx-lg-8 gx-xl-12 gy-6 align-items-center">
                <div className="col-lg-7 order-lg-2">
                  <figure><img className="w-auto" src="./assets/img/illustrations/i4.webp" srcSet="./assets/img/illustrations/i4@2x.webp 2x" alt="" /></figure>
                </div>
                <div className="col-lg-5 mt-12">
                  <div className="swiper-container dots-closer mb-6" data-margin="30" data-dots="true">
                    <div className="swiper">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <blockquote className="icon icon-top fs-lg text-center">
                            <p>“Choosing BytezTech was the best decision we made. Their personalized approach, attention to detail, and seamless integration saved us time and resources, resulting in substantial cost savings.”</p>
                            <div className="blockquote-details justify-content-center text-center">
                              <div className="blockquote-details">
                                <img className="rounded-circle w-12" src="./assets/img/avatars/Kshitij_Mall.webp" srcSet="./assets/img/avatars/Kshitij_Mall.webp 2x" alt="" />
                                <div className="info">
                                  <h5 className="mb-1">Kshitij Mall</h5>
                                  <p className="mb-0">Founder - The Aatmanirbhar Bharat</p>
                                </div>
                              </div>
                            </div>
                          </blockquote>
                        </div>
                        <div className="swiper-slide">
                          <blockquote className="icon icon-top fs-lg text-center">
                            <p>“BytezTech transformed our business with its cutting-edge software solution. We experienced a 30% increase in productivity and a significant boost in customer satisfaction.”</p>
                            <div className="blockquote-details justify-content-center text-center">
                              <div className="blockquote-details">
                                <img className="rounded-circle w-12" src="./assets/img/avatars/Erik.webp" srcSet="./assets/img/avatars/Erik.webp 2x" alt="" />
                                <div className="info">
                                  <h5 className="mb-1">Erik Voges</h5>
                                  <p className="mb-0">CEO - Asset control</p>
                                </div>
                              </div>
                            </div>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reviews